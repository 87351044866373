<template>
  <div class="dashboard-content">
    <!-- Notice -->
    <div class="row">
      <div class="col-md-12 mb-4">
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <span class="alert-inner--icon"><i class="ni ni-like-2" /></span>
          <span class="alert-inner--text"><strong>Success!</strong> This is a success alert—check it out!</span>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="row">
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card   mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User" />
            <div class="py-4">
              <h2 class="text-primary mb-2">
                205
              </h2>
              <p class="text-muted mt-2 mb-0">
                New Leads
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card   mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User" />
            <div class="py-4">
              <h2 class="text-primary mb-2">
                672
              </h2>
              <p class="text-muted mt-2 mb-0">
                Total Views
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card   mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User" />
            <div class="py-4">
              <h2 class="text-primary mb-2">
                902
              </h2>
              <p class="text-muted mt-2 mb-0">
                Online
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card   mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User" />
            <div class="py-4">
              <h2 class="text-primary mb-2">
                20,905
              </h2>
              <p class="text-muted mt-2 mb-0">
                Total Users
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Recent Activity -->
      <div class="col-lg-6 col-md-12 mb-4">
        <div class="card">
          <div class="db-tile">
            <h4>Activities</h4>
          </div>
          <div class="db-activities">
            <div
              class="alert alert-dismissible fade show"
              role="alert"
            >
              <img
                src="/static/images/thumb-1.jpg"
                class="img-fluid rounded-circle shadow-lg"
                width="50"
                height="50"
                alt=""
              >
              <p class="alert-inner--text mb-0 px-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <button
                type="button"
                class="close alert-close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="text-danger d-inline-block"
                >×</span>
              </button>
            </div>
            <div
              class="alert alert-dismissible fade show"
              role="alert"
            >
              <img
                src="/static/images/thumb-2.jpg"
                class="img-fluid rounded-circle shadow-lg"
                width="50"
                height="50"
                alt=""
              >
              <p class="alert-inner--text mb-0 px-3">
                Nullam nec velit quis lacus imperdiet ultricies id sagittis neque.
              </p>
              <button
                type="button"
                class="close alert-close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="text-danger d-inline-block"
                >×</span>
              </button>
            </div>
            <div
              class="alert alert-dismissible fade show"
              role="alert"
            >
              <img
                src="/static/images/thumb-3.jpg"
                class="img-fluid rounded-circle shadow-lg"
                width="50"
                height="50"
                alt=""
              >
              <p class="alert-inner--text mb-0 px-3">
                Praesent ac dolor consequat, imperdiet massa a, gravida urna.
              </p>
              <button
                type="button"
                class="close alert-close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="text-danger d-inline-block"
                >×</span>
              </button>
            </div>
            <div
              class="alert alert-dismissible fade show"
              role="alert"
            >
              <img
                src="/static/images/thumb-4.jpg"
                class="img-fluid rounded-circle shadow-lg"
                width="50"
                height="50"
                alt=""
              >
              <p class="alert-inner--text mb-0 px-3">
                Vestibulum varius turpis vel elit congue, at faucibus
              </p>
              <button
                type="button"
                class="close alert-close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="text-danger d-inline-block"
                >×</span>
              </button>
            </div>
            <div
              class="alert alert-dismissible fade show"
              role="alert"
            >
              <img
                src="/static/images/thumb-1.jpg"
                class="img-fluid rounded-circle shadow-lg"
                width="50"
                height="50"
                alt=""
              >
              <p class="alert-inner--text mb-0 px-3">
                <strong>Ethan Moore</strong> Simply dummy text
              </p>
              <button
                type="button"
                class="close alert-close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  class="text-danger d-inline-block"
                >×</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="card">
          <div class="db-tile ">
            <h4>Upcoming Events</h4>
          </div>
          <div class="db-activities p-4">
            <div class="d-flex align-items-center  pb-3 mb-3">
              <img
                class="rounded"
                src="/static/images/cat-img-2.jpg"
                width="90"
                height="90"
                alt=""
              >
              <div class="flex-grow-1 px-3">
                <h6 class="m-0">
                  Rock Band
                </h6>
                <p class="m-0 text-small text-muted">
                  9986 Bear Hill Dr.
                  Mocksville, NC 27028.
                </p>
              </div>
              <div>
                <button class="btn btn-outline-primary btn-rounded btn-sm">
                  Follow
                </button>
              </div>
            </div>
            <div class="d-flex align-items-center  pb-3 mb-3">
              <img
                class="rounded"
                src="/static/images/cat-img-2.jpg"
                width="90"
                height="90"
                alt=""
              >
              <div class="flex-grow-1 px-3">
                <h6 class="m-0">
                  Rock Band
                </h6>
                <p class="m-0 text-small text-muted">
                  9986 Bear Hill Dr.
                  Mocksville, NC 27028.
                </p>
              </div>
              <div>
                <button class="btn btn-outline-primary btn-rounded btn-sm">
                  Follow
                </button>
              </div>
            </div>
            <div class="d-flex align-items-center  pb-3 mb-3">
              <img
                class="rounded"
                src="/static/images/cat-img-2.jpg"
                width="90"
                height="90"
                alt=""
              >
              <div class="flex-grow-1 px-3">
                <h6 class="m-0">
                  Rock Band
                </h6>
                <p class="m-0 text-small text-muted">
                  9986 Bear Hill Dr.
                  Mocksville, NC 27028.
                </p>
              </div>
              <div>
                <button class="btn btn-outline-primary btn-rounded btn-sm">
                  Follow
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Invoices -->
      <div class="col-lg-12 col-md-12 mb-4">
        <div class="card">
          <div class="db-tile">
            <h4>Recent Booking</h4>
          </div>
          <div class="dashboard-list-box table-responsive invoices with-icons">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Booking Date</th>
                  <th>Payment Type</th>
                  <th>Status</th>
                  <th>View Invoice</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <img
                      class="img-fluid rounded-circle shadow-lg"
                      src="/static/images/thumb-1.jpg"
                      width="50"
                      height="50"
                      alt=""
                    >
                  </td>
                  <td>Mark</td>
                  <td>Sep-02-2019</td>
                  <td>Paypal</td>
                  <td><span class=" badge-cat">Booked</span></td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <img
                      class="img-fluid rounded-circle shadow-lg"
                      src="/static/images/thumb-2.jpg"
                      width="50"
                      height="50"
                      alt=""
                    >
                  </td>
                  <td>Jacob</td>
                  <td>Sep-02-2019</td>
                  <td>Credit</td>
                  <td><span class="">Booked</span></td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <img
                      class="img-fluid rounded-circle shadow-lg"
                      src="/static/images/thumb-3.jpg"
                      height="50"
                      width="50"
                      alt=""
                    >
                  </td>
                  <td>Thore</td>
                  <td>Sep-02-2019</td>
                  <td>Paypal</td>
                  <td><span class="badge badge-pill badge-danger text-uppercase">Pending</span></td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <img
                      class="img-fluid rounded-circle shadow-lg"
                      src="/static/images/thumb-4.jpg"
                      height="50"
                      width="50"
                      alt=""
                    >
                  </td>
                  <td>Ashely</td>
                  <td>Sep-02-2019</td>
                  <td>Paypal</td>
                  <td><span class="badge badge-pill badge-danger text-uppercase">Pending</span></td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <img
                      class="img-fluid rounded-circle shadow-lg"
                      src="/static/images/thumb-1.jpg"
                      height="50"
                      width="50"
                      alt=""
                    >
                  </td>
                  <td>Larry the Bird</td>
                  <td>Sep-02-2019</td>
                  <td>Paypal</td>
                  <td><span class="badge badge-pill badge-danger text-uppercase">Pending</span></td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
